import React from "react"
import styles from "./footer.module.css"
import { Link } from "gatsby"

export default function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.footerWrapper}>
        <div>
          <h4 className={styles.footerColumnTitle}>PRODUCTS</h4>
          <Link to='/albums/about/' className={styles.footerColumnLink}>Wedding Albums</Link>
          <br/>
          <Link to='/guest-books/about/' className={styles.footerColumnLink}>Guest Books</Link>
          <br/>
          <Link to='/children-albums/about/' className={styles.footerColumnLink}>Children Albums</Link>
        </div>

        <div>
          <h4 className={styles.footerColumnTitle}>HELP</h4>

          <Link to='/about/' className={styles.footerColumnLink}>About</Link>
          <br/>
          <Link to='/faq/' className={styles.footerColumnLink}>FAQ</Link>
          <br/>
          <Link to='/cookies/' className={styles.footerColumnLink}>Cookies</Link>
          <br/>
          <Link to='/terms-and-conditions/' className={styles.footerColumnLink}>Terms and conditions</Link>
          <br/>
          <Link to='/privacy-policy/' className={styles.footerColumnLink}>Privacy policy</Link>
          <br/>
        </div>

        <div>
          <h4 className={styles.footerColumnTitle}>PERSONALISATION</h4>
          <Link to='/albums/how-it-works/' className={styles.footerColumnLink}>How it works</Link>
          <br/>

          <Link to='/cover-debossing/metal-type-debossing/' className={styles.footerColumnLink}>Metal Type
            Debossing</Link>
          <br/>

          <Link to='/uv-printing/uv-printing/' className={styles.footerColumnLink}>UV Printing</Link>
          <br/>

        </div>

        <div>
          <h4 className={styles.footerColumnTitle}>EXTRA</h4>

          <Link to='/albums/cover-styles/' className={styles.footerColumnLink}>Album Cover Styles</Link>
          <br/>

          <Link to='/albums/swatches/' className={styles.footerColumnLink}>Album Swatches</Link>
          <br/>

          <Link to='/guest-books/swatches/' className={styles.footerColumnLink}>Guest Book Swatches</Link>
          <br/>


        </div>
      </div>
      <div className={styles.copyright}>
        © 2020 PHOTOTAILORS ALL RIGHTS RESERVED
      </div>
    </div>
  )
}
