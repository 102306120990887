import React from "react"

import Helmet from "react-helmet"
import BurgerMenu from "./burgerMenu"
import Menu from "./menu"
import Footer from "./footer"

export default function Page({title, children, location}) {
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title}/>
        <meta name="robots" content='index,follow'/>
        <meta name="description" content="PhotoTailors – Wedding Photo Albums and Photo books"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1"/>
      </Helmet>
      <BurgerMenu/>
      <Menu location={location}/>

      {children}

    <Footer/>
    </div>
  )
}