import React from "react"
import classnames from "classnames";
import styles from "./menu.module.css"
import { Link } from "gatsby"

export default function Menu({ location }) {
  let paths = location.pathname.split("/");
  const currentPath = paths[1];
  let submenu = null

  switch (currentPath) {
    case "albums":
      submenu = (
        <div className={styles.secondary}>
          <div className={styles.secondaryItems}>
            <div className={styles.menuItem}>
              <Link to='/albums/about/' activeClassName={styles.secondaryItemsActive}>About</Link>
            </div>
            <div className={styles.menuItem}>
              <Link to='/albums/how-it-works/' activeClassName={styles.secondaryItemsActive}>How it works</Link>
            </div>
            <div className={styles.menuItem}>
              <Link to='/albums/cover-styles/' activeClassName={styles.secondaryItemsActive}>Album cover styles</Link>
            </div>
            <div className={styles.menuItem}>
              <Link to='/albums/swatches/' activeClassName={styles.secondaryItemsActive}>Swatches</Link>
            </div>
            <div className={styles.menuItem}>
              <Link to='/albums/pricing/' activeClassName={styles.secondaryItemsActive}>Pricing</Link>
            </div>
            <div className={styles.menuItem}>
              <Link to='/albums/shipping/' activeClassName={styles.secondaryItemsActive}>Shipping</Link>
            </div>
            <div className={styles.menuItem}>
              <Link to='/albums/gallery/' activeClassName={styles.secondaryItemsActive}>Gallery</Link>
            </div>
            <div className={styles.buyButton}>
              <Link to='https://www.etsy.com/shop/AlbumHut/'>Order now</Link>
            </div>
          </div>
        </div>
      )
      break

    case "guest-books":
      submenu = (
        <div className={styles.secondary}>
          <div className={styles.secondaryItems}>
            <div className={styles.menuItem}>
              <Link to='/guest-books/about/' activeClassName={styles.secondaryItemsActive}>About</Link>
            </div>
            <div className={styles.menuItem}>
              <Link to='/guest-books/swatches/' activeClassName={styles.secondaryItemsActive}>Swatches</Link>
            </div>
            <div className={styles.menuItem}>
              <Link to='/guest-books/pricing/' activeClassName={styles.secondaryItemsActive}>Pricing</Link>
            </div>
            <div className={styles.menuItem}>
              <Link to='/guest-books/shipping/' activeClassName={styles.secondaryItemsActive}>Shipping</Link>
            </div>
            <div className={styles.buyButton}>
              <Link to='https://shop.phototailors.com/'>Order now</Link>
            </div>
          </div>
        </div>
      )
      break

    case "children-albums":
      submenu = (
        <div className={styles.secondary}>
          <div className={styles.secondaryItems}>
            <div className={styles.menuItem}>
              <Link to='/children-albums/about/' activeClassName={styles.secondaryItemsActive}>About</Link>
            </div>
            <div className={styles.menuItem}>
              <Link to='/children-albums/pricing/' activeClassName={styles.secondaryItemsActive}>Pricing</Link>
            </div>
            <div className={styles.menuItem}>
              <Link to='/children-albums/shipping/' activeClassName={styles.secondaryItemsActive}>Shipping</Link>
            </div>
            <div className={styles.buyButton}>
              <Link to='https://www.etsy.com/shop/AlbumHut/'>Order now</Link>
            </div>
          </div>
        </div>
      )
      break
    case "cover-debossing":
      submenu = (
        <div className={styles.secondary}>
          <div className={styles.secondaryItems}>
            <div className={styles.menuItem}>
              <Link to='/cover-debossing/metal-type-debossing/' activeClassName={styles.secondaryItemsActive}>What is debossing</Link>
            </div>
            <div className={styles.menuItem}>
              <Link to='/cover-debossing/custom-dies/' activeClassName={styles.secondaryItemsActive}>Custom dies</Link>
            </div>

          </div>
        </div>
      )
      break
    case "uv-printing":
      submenu = (
        <div className={styles.secondary}>
          <div className={styles.secondaryItems}>
            <div className={styles.menuItem}>
              <Link to='/uv-printing/uv-printing/' activeClassName={styles.secondaryItemsActive}>What is UV printing</Link>
            </div>

          </div>
        </div>
      )
      break
    case "faq":
      submenu = (
        <div className={styles.secondary}>
          <h2>FAQ</h2>
        </div>
      )
      break;
    case "cookies":
      submenu = (
        <div className={styles.secondary}>
          <h2>cookies</h2>
        </div>
      )
      break;
    case "terms-and-conditions":
      submenu = (
        <div className={styles.secondary}>
          <h2>TERMS AND CONDITIONS</h2>
        </div>
      )
      break;
    case "privacy-policy":
      submenu = (
        <div className={styles.secondary}>
          <h2>Privacy Policy</h2>
        </div>
      )
      break;
    default:
      break;
  }


  return (
    <div>
      <div className={styles.main}>
        <div className={styles.left}>
          <div className={[classnames(styles.menuItem, {
            [styles.menuItemArrow]: currentPath === 'albums'
          })].join(" ")}>
            <Link to='/albums/about/'>Albums</Link>
          </div>
          <div className={[classnames(styles.menuItem, {
            [styles.menuItemArrow]: currentPath === 'guest-books'
          })].join(" ")}>
            <Link to='/guest-books/about/'>Guest books</Link>
          </div>
          <div className={[classnames(styles.menuItem, {
            [styles.menuItemArrow]: currentPath === 'children-albums'
          })].join(" ")}>
            <Link to='/children-albums/about/'>Children albums</Link>
          </div>
        </div>
        <div className={styles.logo}>
          <Link to='/'>
            <img className={styles.logoImage} itemProp="image"
                 src="/images/logo.svg"
                 alt="dark logo"/>
          </Link>
        </div>
        <div className={styles.right}>
          <div className={[classnames(styles.menuItem, {
            [styles.menuItemArrow]: currentPath === 'cover-debossing'
          })].join(" ")}>
            <Link to='/cover-debossing/metal-type-debossing/' href='#'>Debossing</Link>
          </div>
          <div className={[classnames(styles.menuItem, {
            [styles.menuItemArrow]: currentPath === 'uv-printing'
          })].join(" ")}>
            <Link to='/uv-printing/uv-printing/'>UV printing</Link>
          </div>
          <div className={styles.menuItem}>
            <Link to='/contact/'>Contact us</Link>
          </div>
        </div>
      </div>
      {submenu}
    </div>
  )
}