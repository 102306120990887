import React from "react"
import { slide as Menu } from "react-burger-menu"
import { Link } from "gatsby"

import styles from './burgerMenu.module.css'
import menuStyles from './menu.module.css'

export default function BurgerMenu() {
  return (
    <div className={styles.main}>
      <Menu styles={
        {
          bmBurgerButton: {
            position: "absolute",
            width: "18px",
            height: "10px",
            left: "30px",
            top: "28px"
          },
          bmBurgerBars: {
            background: "#373a47"
          },
          bmBurgerBarsHover: {
            background: "#a90000"
          },
          bmCrossButton: {
            height: "24px",
            width: "24px"
          },
          bmCross: {
            background: "#bdc3c7"
          },
          bmMenuWrap: {
            position: "fixed",
            height: "100%"
          },
          bmMenu: {
            overflow: "auto",
            background: "white",
            padding: "2.5em 1.5em 0",
            fontSize: "1.15em"
          },
          bmMorphShape: {
            fill: "#373a47"
          },
          bmItemList: {
            color: "#b8b7ad"
          },
          bmItem: {
            display: "block"
          },
          bmOverlay: {
            background: "rgba(0, 0, 0, 0.3)"
          }
        }
      }>
        <div className={menuStyles.menuItemLarge}>
          <div>Shop</div>
        </div>
        <div className={menuStyles.menuItem}>
          <Link to='https://www.etsy.com/shop/AlbumHut/'>Wedding Albums</Link>
        </div>
        <div className={menuStyles.menuItem}>
          <Link to='https://shop.phototailors.com/'>Guest Books</Link>
        </div>
        <div className={menuStyles.menuItem}>
          <Link to='https://www.etsy.com/shop/AlbumHut/'>Children Albums</Link>
        </div>

        <div className={menuStyles.menuItemLarge}>
          <Link to='/albums/about/'>Albums</Link>
        </div>
        <div className={menuStyles.menuItem}>
          <Link to='/albums/about/'>About</Link>
        </div>
        <div className={menuStyles.menuItem}>
          <Link to='/albums/how-it-works/'>How it works</Link>
        </div>
        <div className={menuStyles.menuItem}>
          <Link to='/albums/cover-styles/'>Cover styles</Link>
        </div>
        <div className={menuStyles.menuItem}>
          <Link to='/albums/swatches/'>Swatches</Link>
        </div>
        <div className={menuStyles.menuItem}>
          <Link to='/albums/pricing/'>Pricing</Link>
        </div>
        <div className={menuStyles.menuItem}>
          <Link to='/albums/shipping/'>Shipping</Link>
        </div>
        <div className={menuStyles.menuItem}>
          <Link to='/albums/gallery/'>Gallery</Link>
        </div>


        <div className={menuStyles.menuItemLarge}>
          <Link to='/guest-books/about/'>Guest books</Link>
        </div>
        <div className={menuStyles.menuItem}>
          <Link to='/guest-books/about/'>About</Link>
        </div>
        <div className={menuStyles.menuItem}>
          <Link to='/guest-books/swatches/'>Swatches</Link>
        </div>

        <div className={menuStyles.menuItem}>
          <Link to='/guest-books/pricing/'>Pricing</Link>
        </div>
        <div className={menuStyles.menuItem}>
          <Link to='/guest-books/shipping/'>Shipping</Link>
        </div>


        <div className={menuStyles.menuItemLarge}>
          <Link to='/children-albums/about/'>Children albums</Link>
        </div>
        <div className={menuStyles.menuItem}>
          <Link to='/children-albums/about/'>About</Link>
        </div>

        <div className={menuStyles.menuItem}>
          <Link to='/children-albums/pricing/'>Pricing</Link>
        </div>
        <div className={menuStyles.menuItem}>
          <Link to='/children-albums/shipping/'>Shipping</Link>
        </div>

        <div className={menuStyles.menuItemLarge}>
          <Link to='/cover-debossing/metal-type-debossing/'>Debossing</Link>
        </div>
        <div className={menuStyles.menuItem}>
          <Link to='/cover-debossing/metal-type-debossing/'>What is debossing</Link>
        </div>
        <div className={menuStyles.menuItem}>
          <Link to='/cover-debossing/custom-dies/'>Custom dies</Link>
        </div>

        <div className={menuStyles.menuItemLarge}>
          <Link to='/uv-printing/uv-printing/'>UV Printing</Link>
        </div>
        <div className={menuStyles.menuItem}>
          <Link to='/uv-printing/uv-printing/'>What is UV Printing</Link>
        </div>

        <br/>
        <br/>
        <div className={menuStyles.menuItem}>
          <Link to='/contact/'>Contact us</Link>
        </div>
        <br/>
        <br/>

      </Menu>
    </div>
  )
}